.HotelBookingView {
  // padding: 24px;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .HeaderContainer {
    height: 40px;
  }
  .TableContainer {
    // display: flex;
    // flex-direction: column;
    // flex: 1;
    .ag-root-wrapper {
      border: none !important;
    }

   
  }
  .TabContainer {
    height: 100%;
    // margin-top: 12px;
  }
}
iframe {
  border: none !important;
}
.activityButton{
  background-color: #0A8394;
  color: white;
  position: absolute;
  right: 0px;
}
