.MailActivityContainer {
    .TabContainer {
      padding: 12px 20px;
    }
    .ActivityCard {
      .TopContent {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .MailActivityContainer {
    // border-left: 1px solid rgb(233 236 240);
    // padding-left: 12px;
    // width: 300px;
    // overflow-y: auto;

    .ActivityList {
      margin-top: 12px;
      // overflow-y: auto;
      // height: 90%;
    }
    .ActivityCard {
      margin-bottom: 12px;
      transition: all 0.4s;
      cursor: pointer;
      padding: 6px 20px;
      &:hover {
        background-color: rgba(220, 231, 253, 0.3);
      }
    }
  }