.HotelBookingView {
  // padding: 24px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  .HeaderContainer {
    height: 40px;
  }
  .TableContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    // margin-top: 12px;
    .ag-root-wrapper {
      border: none !important;
    }
  }
  .TabContainer {
    height: 100%;
    margin-top: 12px;
  }
}
iframe {
  border: none !important;
}
